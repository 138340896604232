import { Box, Grid } from "@mui/material";
import React from "react";
import ServiceCard from "../info-card/ServiceCard";
import engineer from "../../assets/engineer.jpg";

const TeamCard = () => {
  const data = [
    {
      id: "1",
      img: engineer,
      name: "Major. Mohamed Kamel",
      JobTitle: "Chairman of the Board",
    },
    {
      id: "2",
      img: engineer,
      name: "Dr. Ahmed Faisal ",
      JobTitle: "Heard Group",
    },
  ];
  return (
    <>
      <Box sx={{ margin: "10px" }}>
        <Grid container lg={12}>
          {data.map((item) => (
            <Grid item lg={3} key={item.id}>
              <ServiceCard
                img={item.img}
                name={item.name}
                JobTitle={item.JobTitle}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TeamCard;
